import React, { Component } from 'react';
import ReactPlayer from 'react-player';
import './video-detail.less';
import { videoData } from '../data';
import {
  Link
} from "react-router-dom";
class VideoDetailPage extends Component {

  constructor (props) {
    super(props);
    this.state = {
      id: props.match.params.id
    }
    this.playerRef = React.createRef();
    this.listRef = React.createRef();
    this.onEnded = this.onEnded.bind(this);
    this.onReady = this.onReady.bind(this);
    this.onPause = this.onPause.bind(this);
    this.onStart = this.onStart.bind(this);
    this.onPlay = this.onPlay.bind(this);

    this.isPlaying = true;
    this.videoData = props.user && props.user.type === 1 ? videoData.filter(item => item.id !== '755398525') : videoData;
  }

  componentDidMount() {
    if (this.listRef.current) {
      let current = this.getCurrentIndex();
      this.listRef.current.scrollLeft = (current + 1) * 216;
    }

    const index = this.getVideoIndex(this.props.match.params.id)
    console.log('ComponentDidMount', index)

    window.gtag('event', 'view', {
      'event_category': `${this.props.user.name}`,
      'event_label': `${this.props.user.name}${this.props.user.username}_${index + 1}`,
      'value': 1
    })
  }

  componentDidUpdate() {
    if (this.props.match.params.id !== this.state.id) {
      this.setState({
        id: this.props.match.params.id
      })

      const index = this.getVideoIndex(this.props.match.params.id)
      console.log('componentDidUpdate', index)

      window.gtag('event', 'view', {
        'event_category': `${this.props.user.name}`,
        'event_label': `${this.props.user.name}${this.props.user.username}_${index + 1}`,
        'value': 1
      })
    }
  }

  getVideoIndex(videoId) {
    const index = this.videoData.findIndex(item => item.id === videoId);
    return index
  }

  onReady () {
    console.log('onReady')
    this.isPlaying = true;
  }

  onEnded () {
    let current = this.getCurrentIndex();

    if (current === this.videoData.length - 1) {
      console.log('您已觀看完全部課程內容')
    } else {
      this.props.history.replace(`/video/${this.videoData[current + 1].id}`)
    }

    console.log('onEnded')
  }

  onStart () {
    console.log('onStart')
    this.isPlaying = true;
  }

  onPause () {
    console.log('onPause')
    this.isPlaying = false;
  }

  onPlay () {
    console.log('onPlay')
    if (!this.isPlaying) {
      this.isPlaying = true;
      this.startTime = new Date().getTime()
    }
  }

  getCurrentIndex () {
    let current = 0
    for (let index = 0; index < this.videoData.length; ++ index) {
      if (this.videoData[index].id === this.state.id) {
        current = index;
        break;
      }
    }
    return current
  }

  getVideoTitle () {
    const video = this.videoData.find(item => item.id === this.state.id);
    return video ? video.title : ''
  }

  getVideoUrl () {
    const video = this.videoData.find(item => item.id === this.state.id);
    return video ? video.url : ''
  }

  render () {
    return (
      <div className="container-detail">
        <div className="detail-content">
          <div className="title">{ this.getVideoTitle() }</div>
          <div className="pad-video-player">
            <div className="video-player-wrapper">
              <div className="video-player-inner">
                <ReactPlayer
                  key={this.state.id}
                  ref={this.playerRef}
                  url={this.getVideoUrl()}
                  controls
                  width="100%"
                  height="100%"
                  playing={true}
                  onReady={this.onReady}
                  onEnded={this.onEnded}
                  onStart={this.onStart}
                  onPause={this.onPause}
                  onPlay={this.onPlay}
                  config={{
                    vimeo: {
                      controls: true
                    }
                  }}
                />
              </div>
            </div>
          </div>
          <div className="pad-next-play">
            更多影片
            <div  className="pad-detail-list" ref={this.listRef}>
              {
                this.videoData.map(item => {
                  return (
                    <div className="video-item" key={item.id}>
                    <Link to={ `/video/${item.id}` } replace>
                      <div className="cover">
                        <img src={ item.image } alt={item.title} />
                        <span className="duration">{ item.duration }</span>
                      </div>
                      <div className="title">{ item.title }</div>
                    </Link>
                  </div>
                  );
                })
              }
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default VideoDetailPage;
