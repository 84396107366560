import React, { Component } from 'react';
import {
  Link
} from "react-router-dom";
import './video-list.less';
import { videoData } from '../data';
class VideoListPage extends Component {
  constructor (props) {
    super(props);
    this.videoData = props.user && props.user.type === 1 ? videoData.filter(item => item.id !== '755398525') : videoData;
  }
  render () {
    return (
      <div className="video-list-page">
        <div>
          <img className="banner" src={ 'https://d2hrw3sjxwwsb1.cloudfront.net/img/pic-banner/banner-course.jpg' } alt="banner"/>
        </div>
        <div className="video-list-wrapper">
          <div className="video-grid-list">
            {
              this.videoData.map(item => {
                return (
                  <div className="video-item" key={item.id}>
                    <Link to={ `/video/${item.id}` }>
                      <div className="cover">
                        <img src={ item.image } alt={item.title} />
                        <span className="duration">{ item.duration }</span>
                      </div>
                      <div className="title">{ item.title }</div>
                    </Link>
                  </div>
                );
              })
            }
          </div>
        </div>
        <div className="pad-footer">
          ©2021 GIVE-CIRCLE CO.,LTD All rights reserved.
        </div>
      </div>
    );
  }
}

export default VideoListPage;
